import { useTranslation } from "react-i18next";
import { useToggle } from "react-use";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import PublicIcon from "@mui/icons-material/PublicRounded";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";

import { Button } from "@noticia/ui";

import PopOverList from "~/components/atoms/PopOver/PopOverList";
import PopOverListItem from "~/components/atoms/PopOver/PopOverListItem";

const languagesNames: Record<string, string> = {
	fr: "Français",
	en: "English",
	es: "Español",
	pt: "Português",
	it: "Italiano",
};

const ChangeLanguage = () => {
	const { i18n } = useTranslation();

	const [isOpenMenu, toggleMenu] = useToggle(false);

	const userLanguage =
		languagesNames[i18n.resolvedLanguage || "en"] || languagesNames.en;

	const chooseLanguage = async (language: string) => {
		await i18n.changeLanguage(language);
		toggleMenu();
	};

	return (
		<div>
			<Backdrop open={isOpenMenu} onClick={toggleMenu} sx={{ zIndex: 2000 }} />
			<Box position="relative" role="menu">
				{isOpenMenu && (
					<PopOverList>
						<PopOverListItem
							icon={<img src="/flags/italy.png" height={16} width={16} />}
							selected={i18n.language === "it"}
							onClick={() => chooseLanguage("it")}
						>
							{languagesNames.it}
						</PopOverListItem>
						<PopOverListItem
							icon={<img src="/flags/portugal.png" height={16} width={16} />}
							selected={i18n.language === "pt"}
							onClick={() => chooseLanguage("pt")}
						>
							{languagesNames.pt}
						</PopOverListItem>
						<PopOverListItem
							icon={
								<img
									src="/icons/flag_es.svg"
									alt="Spanish flag"
									height={16}
									width={16}
								/>
							}
							selected={i18n.language === "es"}
							onClick={() => chooseLanguage("es")}
						>
							{languagesNames.es}
						</PopOverListItem>
						<PopOverListItem
							icon={
								<img
									src="/icons/flag_en.svg"
									alt="English flag"
									height={16}
									width={16}
								/>
							}
							selected={i18n.language === "en"}
							onClick={() => chooseLanguage("en")}
						>
							{languagesNames.en}
						</PopOverListItem>
						<PopOverListItem
							icon={
								<img
									src="/icons/flag_fr.svg"
									alt="French flag"
									height={16}
									width={16}
								/>
							}
							selected={i18n.language === "fr"}
							onClick={() => chooseLanguage("fr")}
						>
							{languagesNames.fr}
						</PopOverListItem>
					</PopOverList>
				)}
			</Box>

			<Button
				variant="link"
				color="blue"
				size="small"
				startIcon={<PublicIcon sx={{ height: 12, width: 12 }} />}
				endIcon={
					isOpenMenu ? (
						<KeyboardArrowDownIcon sx={{ height: 12, width: 12 }} />
					) : (
						<KeyboardArrowUpIcon sx={{ height: 12, width: 12 }} />
					)
				}
				onClick={toggleMenu}
			>
				{userLanguage}
			</Button>
		</div>
	);
};

export default ChangeLanguage;
